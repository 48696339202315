<template>
    <div>
        <div class="endBack2 active">
            <div class="txttitle">
                <span class="endtext">国家统一法律职业资格考试计算机化考试模拟答题演示</span>
            </div>
            <div class="buthome">
                <input type="button" value="关闭窗口" class="inphome" @click="handleClose">
                <input type="button" value="查看答案" class="inphome look" @click="handleShowAnswer()">
            </div>
        </div>
        <!--<el-dialog title="查看解析" :visible.sync="dialogVisible" width="400px" :modal-append-to-body="false">-->
        <!--  <div>-->
        <!--    <div style="text-align: center">-->
        <!--      <h4>请使用微信扫描下方二维码查看解析内容</h4>-->
        <!--    </div>-->
        <!--    <div style="text-align: center">-->
        <!--      <vue-qrcode :value="codeUrl" :options="{ width: 200 }"></vue-qrcode>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--</el-dialog>-->
        <!-- 二维码 -->
        <!-- 答案解析 -->
        <el-dialog title="答案与解析" :visible.sync="showCode" :modal-append-to-body="false" width="80%">
            <div>
                <div style="margin-top: 20px;">
                    <el-card header="案情内容"
                             :body-style="{height: '180px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                        {{ subjectiveStatistic.bdSubjective.caseDetail }}
                    </el-card>
                </div>
                <div v-for="(currentData, key) in subjectiveStatistic.bdSubjectiveQuestionList" :key="key">
                    <div style="font-size: 18px; margin-top: 30px; margin-bottom: 20px; margin-left: 10px;">
                        第{{ key + 1 }}问：{{ currentData.questionStem }}（{{ currentData.questionScore }}分）
                    </div>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-card header="你的答案"
                                     :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{
                                    subjectiveStatistic.subjectiveQuestionRecordList[key].bdSubjectiveQuestionRecord.studentAnswer
                                }}
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card header="参考答案"
                                     :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{ currentData.suggestAnswer }}
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "paperEnd",
    data() {
        return {
            dialogVisible: false,
            codeUrl: '',
            showCode: false,
            subjectiveStatistic: {
                bdSubjective: {},
                subjectiveQuestionRecordList: []
            }
        }
    },
    mounted() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'index'})
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#2d6dae')
    },
    methods: {
        // 展示答案
        handleShowAnswer() {
            // 获取单个统计信息
            this.$api.get('/subjective/getBankSubjectiveRecord', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.showCode = true
                this.subjectiveStatistic = res.data
            })
        },
        // 展示二维码
        handleShowCode() {
            this.codeUrl = "http://member.harchinaedu.com/#/pages/subjective/subjectiveTips?id=" + this.$route.query.id + "&type=1"
            this.dialogVisible = true
        },
        // 关闭窗口
        handleClose() {
            this.$router.push({name: 'index'})
        }
    }
}
</script>

<style scoped lang="scss">
.buthome {
    position: absolute;
    top: 590px;
    width: 100%;
    text-align: center;
}

.look {
    margin-left: 25px;
}

.inphome {
    width: 90px;
    background: url(../../../assets/img/submit.jpg) repeat-x;
    border: 1px solid #c60;
    color: #000;
    font-size: 13px;
    cursor: pointer;
    border-radius: 0;
    padding: 6px;
}

.endtext {
    position: relative;
    color: #255dad;
    font-size: 32px;
    font-weight: bolder;
}

.txttitle {
    position: absolute;
    top: 190px;
    width: 100%;
    text-align: center;
}

.endBack2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1024px;
    height: 768px;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
}

.endBack2.active {
    background: url(../../../assets/img/end2.7ecd182.jpg) no-repeat;
}
</style>